* {
  margin: 0;
  padding: 0;
}

.LeftContent {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 901px) {
  html,
  body,
  .App {
    height: 100%;
  }

  .App:before {
    content: '';
    height: 100%;
    float: left;
  }

  .ContentWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .ContentWrapper > div {
    height: 100%;
  }

  .RightContent {
    width: 65%;
    height: 100%;
    background-color: #ffffff;
    z-index: inherit;
    overflow-y: auto;
    float: right;
    background-color: #ffffff;
    background-color: white;
  }
  .LeftContent {
    height: 100%;
    width: 35%;
    float: left;
  }
}
