#SectionStage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 901px) {
    #SectionStage {
        display: block;
    }
}