.SectionDetail{
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    flex:1;
    width: 90%;
    height: 95%;
    padding-top: 5%;
    padding-left: 5%;
    padding-right: 5%;
}
.detailIntro{
    width:100%;
}
.SectionDetail h1{
    text-align: center;
    color:#DA3E52;
    font-size: 2em;
    padding: 2%;
}
.projectDetail{
    width: 100%;
    display: flex;
    flex-direction: column;  
}
.projectDetail .detailImage{
    width: 100%;
    max-height: 420px;
    padding-top: 25px;
    padding-bottom: 25px;
}
.projectDetail .detailContent{
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 18px;
    width: 100%;
    margin-top:25px;
    margin-bottom: 25px;
}