#NavBar{
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;
    background-color: #2F4858;
    background-color: #8e9b90;
    background-color: white;
    background-color: #0353A4;
    background-color: #6e71b6;
    color: #0353A4;
    color: #DA3E52;
    color: #6c6fbf;
    color:#63f8b5;
    color: #9fa0c3;
    color:#63f8b5;
    color:#DA3E52;
    color:#63f8b5;
    background-repeat: no-repeat;
    background-size: cover;
}
.aboutBar{
    display: flex;
    align-items: center;
    margin: 10% 8%;
}
.jamesImage{
    display:none;
    margin: 0 auto;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 300px;
    min-height: 300px;
    width: 70%;
    height: 33%;
}
.aboutContent{
    background-color: rgba(0,0,0,0.5);
    font-family: Arial, Helvetica, sans-serif;
    font-size: .8em;
    color: #0353A4;
    color: white;
    display: flex;
    flex-direction: column;
    margin-right: 10%;
    padding: 4%;
    width: 100%;
}
.aboutContent h1{
    font-size: 1.5em;
    margin: 0;
}
.aboutContent h2{
    margin: 0;
    font-size: 1.2em;
}
.navigation-links{
    display: flex;
    font-size: 300%;
    justify-content: space-evenly;
    width: 50%;
    padding: 5% 10%;
}
.navigation-links *{
    color:#c3fce2!important;

}
.MuiSvgIcon-root:hover{
    background-color: #9fa0c3!important;
}
@media screen and (max-width: 900px){
    .jamesImage{
        display: none;
    }
    .aboutContent{
        background-color: rgba(0,0,0,0.5);
        font-size: 1em;
    }
    .navigation-links{
        justify-content: left;
        height: 10%;
        padding: 0;
        width:100%;
    }
    #NavBar{
        width: 100%;
        height: 100%;
        overflow: visible;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}