.SectionGrid{
    margin: 0 auto;
    box-sizing: border-box;
    float: left;
    max-width: 700px;
    margin: 0 auto;
    display:inline-block;
    padding: 40px;
    width: 100%;
    height: 100%;   
}
.gridTitleContent{
    font-family: Arial, Helvetica, sans-serif;
    color: #63f8b5;
    text-shadow: 3px 3px 5px rgba(0,0,0,.7);
    padding-top: 5%;
    padding-bottom: 1%;
}
.gridTitleContent h1{
    font-size: 3em;
    padding: 0.1em 0;
}
.gridTitleContent p{
    font-size: 1em;
}
.games {
    display: block;
}
.games .game {
    position: relative;
    width: 100%;
    float: left;
    margin-right: 40px;
    margin-bottom: 40px;
    box-shadow: 8px 8px 0px #63f8b5;
    border-radius: 2px;
}
.games .game {
    text-decoration: none;
}
.game .gamePreview {
    position: relative;
    width: 100%;
    height: 260px;
    float: left;
    overflow: hidden;
    border-radius: 2px;
}
.gamePreview .gameImg {
    position: absolute;
    left:0;
    top: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center center;
    float: left;
    border-radius: 2px;
    transition: all .5s;
}
.gamePreview:hover .gameImg{
    transform: scale(1.10);
}
.gamePreview .gameFade {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    float: left;
    border-radius: 2px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 25%, rgba(0,0,0,.7) 100%);
}
.gamePreview .gameBorder {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 10px;
    right: 10px;
    border-radius: 2px;
    border: 2px solid rgba(255,255,255,.25);
    transition: all 0.25s;
}
.games .gameTitle {
    position: absolute;
    left: 32px;
    bottom: 20px;
    color: white;
    font-weight: bold;
    font-size: 2.0em;
}

@media (min-width : 960px) {
   .SectionGrid{
        width: 700px;
        padding: 0 0 0 40px;
        float: left;
        box-sizing: border-box;
   }
    
}
